<template>
    <div>
        <dealer-product-update-dialog
            v-model="productDialogActive"
            :dealer="dealer"
            :product-ids="selectedProducts" />
        <platform-authentication-slide-out
            v-model="showAuthenticateSlideOut"
            :channel="authenticateModalData"
            slide-out-title="Set up Token user"
            :redirect-path="'/dealers/' + dealer.id + '/channel-setup/' + authenticateModalData.name" />
        <loader v-if="loading" />
        <div
            v-else-if="!isEligible"
            class="marketing">
            <div class="marketing-inside">
                <div class="marketing-robot">
                    <img src="/img/robot2.svg">
                </div>
                <div class="marketing-left">
                    <div class="marketing-title">
                        Are you getting the most out of your inventory?
                    </div>
                    <div class="marketing-desc">
                        Seamlessly syndicate and manage your inventory in real-time to deliver the
                        most relevant vehicles to in-market auto shoppers across multiple channels!
                    </div>
                    <div class="marketing-channels">
                        <div class="marketing-subtitle">
                            To get started, choose from one of our inventory products below
                        </div>
                        <div>
                            <inventory-channel
                                v-for="(channel, index) in channels"
                                :key="index"
                                :channel="channel"
                                :has-inventory="hasInventory"
                                :is-eligible="isEligible"
                                @open-auth-modal="openAuthenticateModal"
                                @open-product-dialog="openProductDialog" />
                        </div>
                    </div>
                </div>
                <div class="marketing-right">
                    <div>
                        <div class="marketing-subtitle-sm">
                            Easily setup vehicle inventory
                        </div>
                        <div class="marketing-desc-sm">
                            Use website inventory to get started within minutes
                            or tap feeds directly for the most accurate result
                        </div>
                    </div>
                    <div>
                        <div class="marketing-subtitle-sm">
                            Transform vehicle inventory into ads
                        </div>
                        <div class="marketing-desc-sm">
                            Quickly activate your inventory across multiple channels by
                            transforming it into native catalogs for each platform
                        </div>
                    </div>
                    <div>
                        <div class="marketing-subtitle-sm">
                            Dynamic Retargeting
                        </div>
                        <div class="marketing-desc-sm">
                            Meet EventFlow, our vehicle-specific event tracking feature
                            that captures user interaction with a dealer's inventory
                        </div>
                    </div>
                    <div>
                        <div class="marketing-subtitle-sm">
                            Inventory Dashboard
                        </div>
                        <div class="marketing-desc-sm">
                            Once your inventory is setup you can easily manage it
                            within our comprehensive management dashboard
                        </div>
                    </div>
                    <div>
                        <div class="marketing-subtitle-sm">
                            Supported Providers
                        </div>
                        <div class="marketing-desc-sm">
                            We support over 120+ automotive inventory providers
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="activation">
            <div class="mb-3 title-holder">
                <span class="activation-title">Channel Activation</span>
                <span class="description">
                    Get the most of your inventory and BuyerBridge by activating it across our always growing list of channels and opportunities to reach new customers within them!
                </span>
            </div>
            <div class="channels">
                <inventory-channel
                    v-for="(channel, index) in channels"
                    :key="index"
                    :channel="channel"
                    :has-inventory="hasInventory"
                    @apply-filtering="applyFiltering"
                    @open-auth-modal="openAuthenticateModal"
                    @open-product-dialog="openProductDialog" />
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/globals/Loader';
import InventoryChannel from '@/components/inventory-dashboard/InventoryChannel';
import DealerProductUpdateDialog from '@/components/globals/DealerProductUpdateDialog';
import PlatformAuthenticationSlideOut from '@/components/resellers/settings/PlatformAuthenticationSlideOut';
import { inventoryChannels } from '@/helpers/inventory-dashboard/channels';
import { mapGetters, mapState, mapActions } from 'vuex';
import { getNotEnrolledChannels } from '@/helpers/getNotEnrolledChannels';
import { getAvailableChannels } from '@/helpers/getAvailableChannels';
import { SET_ACTIVATED_CHANNELS } from '@/store/mutation-types';

export default {
    components: {
        InventoryChannel,
        Loader,
        PlatformAuthenticationSlideOut,
        DealerProductUpdateDialog
    },
    props: {
        aggregations: {
            type: Object,
            default: () => ({})
        },
        hasInventory: {
            type: Boolean,
            required: true
        },
        isEligible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            availableChannels: [],
            notEnrolledChannels: {},
            showAuthenticateSlideOut: false,
            authenticateModalData: {},
            channels: null,
            productDialogActive: false,
            selectedProducts: [],
            hasActivation: false
        };
    },
    computed: {
        ...mapGetters([
            'dealerPlatforms',
            'dealerProducts',
            'activeDealerOnboardings'
        ]),
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            agency: state => state.agency.currentAgency
        }),
        dealerChannels() {
            const channels = this.dealerPlatforms.map(platform => {
                return {
                    ...platform,
                    products: this.dealerProducts.filter(product => {
                        const visibility = product.product_settings?.visibility;
                        return product.platform_id == platform.id && visibility !== 'coming_soon';
                    })
                };
            });
            const activeOnboardings = this.activeDealerOnboardings;
            const channelsWithCompletingInfo = channels.map(function(channel) {
                activeOnboardings.forEach(onboarding => {
                    if (!channel.unfinished_onboarding_id) {
                        if (onboarding.products.data[0]?.platform_id == channel.products[0]?.platform_id) {
                            channel.unfinished_onboarding_id = onboarding.id;
                            channel.unfinished_onboarding = onboarding;
                        }
                    }
                });
                return channel;
            });
            return channelsWithCompletingInfo;
        },
        enrolledChannels() {
            return this.dealerChannels.filter(channel => channel.products.some(product => {
                const visibility = product.product_settings?.visibility;
                return product.public == true && visibility !== 'coming_soon';
            }));
        },
    },
    watch: {
        async dealer() {
            await this.waitForDealerLoaded();
            this.updateChannels();
        },
        channels() {
            const activatedChannels = this.channels?.filter(channel => channel.activated == true) ?? [];
            const channelsAggregation = [];
            if (activatedChannels.length) {
                activatedChannels.forEach(c => {
                    channelsAggregation.push(c.aggregation);
                });
            }
            this.$store.commit(SET_ACTIVATED_CHANNELS, channelsAggregation);
        }
    },
    async created() {
        await this.waitForDealerLoaded();
        this.updateChannels();
    },
    methods: {
        ...mapActions([
            'waitForDealerLoaded'
        ]),
        applyFiltering(filter) {
            this.$emit('apply-filtering', filter);
        },
        async updateChannels() {
            this.loading = true;
            this.channels = null;
            this.$store.commit(SET_ACTIVATED_CHANNELS, []);
            await this.loadDealerPlatforms();
            this.notEnrolledChannels = await getNotEnrolledChannels(this.availableChannels, this.enrolledChannels, this.agency.id, true);
            this.setChannelsStructure();
            this.populateVehicles();
            this.loading = false;
        },
        async loadDealerPlatforms() {
            const platforms = await this.$apiRepository.getPlatforms();
            this.availableChannels = getAvailableChannels(platforms);
        },
        setChannelsStructure() {
            // eslint-disable-next-line
            this.channels = structuredClone(inventoryChannels);
            this.channels.forEach(channel => {
                // check enrolled
                const enrolled = this.enrolledChannels.filter(enrolled => enrolled.name === channel.channel)[0] ?? null;
                if (enrolled) {
                    if (!enrolled.unfinished_onboarding_id) {
                        const hasValidProduct = enrolled.products.some(p => channel.valid_products.includes(p.id));
                        if (hasValidProduct) {
                            channel.activated = true;
                        } else {
                            channel.needs_upgrade = true;
                        }
                    }
                    Object.assign(channel, enrolled);
                }
                // check not enrolled
                const notEnrolled = this.notEnrolledChannels.filter(notEnrolled => notEnrolled.name === channel.channel)[0] ?? null;
                if (notEnrolled) {
                    channel.is_available = false;
                    Object.assign(channel, notEnrolled);
                }
                // check if unfinished onboarding
                const unfinishedOnboarding = channel.unfinished_onboarding;
                if (unfinishedOnboarding) {
                    const currentProduct = channel.unfinished_onboarding?.products?.data[0].display_name.toLowerCase().includes(channel.channel_type);
                    const firstOnboarding = channel.unfinished_onboarding?.is_first_product_onboarding;
                    if (currentProduct && !firstOnboarding) {
                        channel.activated = true;
                    }
                    // activate channel if it has play_id in unfinished onboarding
                    else if (currentProduct && channel.unfinished_onboarding.play_id) {
                        channel.activated = true;
                    } else if (currentProduct && !channel.unfinished_onboarding.play_id) {
                        channel.unfinished = true;
                    } else {
                        channel.is_available = false;
                    }
                }
            });
        },
        populateVehicles() {
            if (this.aggregations) {
                this.channels.forEach(channel => {
                    const availableVehicles = this.aggregations[`exports.${channel.aggregation}.is_vehicle_eligible`].find(ag => ag.key == 'true') ?? [];
                    const filteredVehicles = this.aggregations[`exports.${channel.aggregation}.is_vehicle_eligible`].find(ag => ag.key == 'false') ?? [];
                    const filteredReasons = this.aggregations[`exports.${channel.aggregation}.filtered_vehicle_reasons.reason.keyword`];
                    const newVehicles = availableVehicles.used?.find(ag => ag.key == 'false') ?? [];
                    const usedVehicles = availableVehicles.used?.find(ag => ag.key == 'true') ?? [];
                    channel.available_vehicles = availableVehicles.count ?? 0;
                    channel.filtered_vehicles = filteredVehicles.count ?? 0;
                    channel.filtered_reasons = filteredReasons;
                    channel.new_vehicles = newVehicles.count ?? 0;
                    channel.used_vehicles = usedVehicles.count ?? 0;
                });
            }
        },
        openAuthenticateModal(data) {
            this.showAuthenticateSlideOut = true;
            this.authenticateModalData = data;
        },
        openProductDialog(product) {
            this.productDialogActive = true;
            this.selectedProducts = [product];
        }
    }
};
</script>

<style lang="scss" scoped>
.activation {
    padding: 15px 35px 15px 35px;
    border-bottom: 1px solid $alabaster-dark;
}
.title-holder {
    display: flex;
    align-items: center;
}
.activation-title {
    font-size: 20px;
    font-weight: 600;
    margin-right: 15px;
}
.description {
    display: inline-block;
    max-width: 60%;
    font-size: 13px;
}
.channels {
    padding-left: 25px;
    text-align: center;
}

// screen with no inventory
.marketing {
    background: linear-gradient(to bottom,#D8C3FE 0%, #C9DFFC 50%, #83D2F5 100%);
}
.marketing-inside {
    padding: 25px 45px 25px 45px;
    display: flex;
    background: linear-gradient(to right,#FFFFFF 0%, #FFFFFF 65%, transparent 100%);
}
.marketing-left {
    width: 72%;
    margin-right: 3%;
    padding-right: 3%;
    position: relative;
    z-index: 2;
}
.marketing-right {
    width: 25%;
    padding-top: 60px;
    padding-left: 15px;
    position: relative;
    z-index: 2;
}
.marketing-title {
    font-size: 32px;
    font-weight: 600;
    color: $blue-bg;
}
.marketing-desc {
    font-size: 21px;
    line-height: 31px;
    color: $gray-dark;
    margin-top: 10px;
}
.marketing-channels {
    margin-top: 30px;
    background-color: #f1f1f1;
    padding: 10px 35px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 5px;
}
.marketing-subtitle {
    color: $blue-bg;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}
.marketing-subtitle-sm {
    color: $blue-bg;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}
.marketing-desc-sm {
    font-size: 11px;
    color: $gray-dark;
    margin-bottom: 35px;
    max-width: 215px;
}
.marketing-robot {
    position: absolute;
    bottom: -8px;
    right: 0;
    z-index: 1;
    img {
        max-width: 282px;
        height: auto;
    }
}
</style>