<template>
    <styled-slideout
        :value="value"
        :width="700"
        @input="handleSlideoutToggle">
        <div class="layout pa-4">
            <div class="flex xs12 mb-3">
                <loader
                    v-if="loading"
                    class="pa-5" />
                <template v-if="currentVehicle">
                    <div class="gray-icons">
                        <template>
                            <div class="layout pb-3">
                                <div class="light-blue--text title-block">
                                    Photo Gallery
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="current-status" />
                    <div class="layout">
                        <div class="flex xs12 text-xs-center">
                            <image-slider
                                class="main-image"
                                :slides="imageUrls" />
                            <!-- <img
                                class="main-image"
                                :src="currentImage.src"> -->
                        </div>
                    </div>

                    <div class="layout wrap">
                        <div
                            v-for="(image, ind) in imageUrls"
                            :key="ind"
                            class="flex xs3 text-xs-center">
                            <img
                                :src="image.src"
                                class="image-item">
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </styled-slideout>
</template>
<script>
import ImageSlider from '@/components/globals/ImageSlider';
import StyledSlideout from '@/components/globals/StyledSlideout';
import Loader from '../globals/Loader';
import ApiQuery from '../../api-query';

export default {
    name: 'VehicleImages',
    components: {
        Loader,
        StyledSlideout,
        ImageSlider,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: {
            type: [Number, String],
            required: true,
        },
        dealer_id: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            currentImage: null,
            currentVehicle: null,
        };
    },
    computed: {
        imageUrls() {
            return this.currentVehicle.images.data.map(image => {
                return {
                    src: image.original_url
                };
            });
        },
    },
    mounted() {
        this.getVehicle();
    },
    methods: {
        getStateVehicle(id) {
            const archivedOptions = ['Active', 'Inactive'];

            return (
                (new ApiQuery('vehicles', id))
                    .withScope('{"vehicles.include_in_marketplace":{}}')
                    .withFields(
                        'vehicles.description'
                    )
                    .withRelationships(
                        'vehicles.dealer_vehicle',
                        'vehicles.images',
                        'vehicles.location'
                    )
                    .setPageNumber(1)
                    .setPageSize(15)
                    .setSortDirection('desc')
                    .setSortField('vin')
                    .setArchivedOptions(archivedOptions)
                    .get()
            );
        },
        async getVehicle() {
            if (this.id === null) {
                return;
            }

            this.loading = true;
            try {
                let response = await this.getStateVehicle(this.id);
                this.currentVehicle = response.data.data;

                this.loading = false;
            } catch(e) {
                console.log(e);
                this.error = true;
            } finally {
                this.loading = false;
            }
        },

        handleSlideoutToggle($event) {
            this.$emit('input', $event);
        }
    },
};
</script>
<style lang="scss" scoped>
.image-item {
    width: 80%;
    margin: 10px 0px 20px 0px;
    height: 100px;
}
.main-image {
    margin:20px 0px 20px 0px;
    height:350px !important;
    width:100%!important;
}

</style>
