<template>
    <div
        class="image-slider"
        :style="{
            height: height
        }">
        <transition-group
            name="fade"
            tag="div">
            <div
                v-for="i in [currentIndex]"
                :key="i"
                class="slide">
                <div class="slide-contents">
                    <img
                        ref="image"
                        :src="currentSlide.src"
                        @load="onImageLoad">
                    <div class="slide-info">
                        <div class="count">
                            {{ currentIndex + 1 }} of {{ slides.length }}
                        </div>
                        <div class="dimensions">
                            {{ dimensions }}
                        </div>
                        <div class="actions">
                            <button
                                v-clipboard:copy="currentSlide.src"
                                v-clipboard:success="onCopySuccess"
                                v-clipboard:error="onCopyError">
                                <icon
                                    name="copy"
                                    size="20"
                                    color="white" />
                            </button>
                            <button
                                @click="onOpenImage(currentSlide)">
                                <icon
                                    name="link"
                                    size="20"
                                    color="white" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
        <button
            class="prev"
            @click="prev()">
            <icon
                name="chevron-left"
                color="white"
                size="15" />
        </button>
        <button
            class="next"
            @click="next()">
            <icon
                name="chevron-right"
                color="white"
                size="15" />
        </button>
    </div>
</template>

<script>
import Icon from '../globals/Icon';

export default {
    name: 'ImageSlider',
    components: {
        Icon
    },
    props: {
        interval: {
            type: Number,
            default: 0
        },
        slides: {
            type: Array,
            required: true
        },
        height: {
            type: String,
            default: '400px'
        }
    },
    data() {
        return {
            timer: null,
            currentIndex: 0,
            dimensions: '',
            imagesLoaded: []
        };
    },

    computed: {
        nextIndex() {
            if (this.slides.length === this.currentIndex + 1) {
                return 0;
            }
            return this.currentIndex + 1;
        },
        previousIndex() {
            if (this.currentIndex === 0) {
                return this.slides.length - 1;
            }
            return this.currentIndex - 1;
        },
        currentSlide() {
            return this.slides[this.currentIndex];
        }
    },

    watch: {
        currentSlide() {
            this.dimensions = '';
        }
    },

    mounted() {
        this.startSlide();
        this.loadPreviousSlide();
        this.loadNextSlide();
    },

    unmounted() {
        this.stopSlide();
    },

    methods: {
        startSlide() {
            if (this.interval) {
                this.timer = setInterval(this.next, this.interval);
            }
        },
        stopSlide() {
            clearInterval(this.timer);
        },
        next() {
            this.currentIndex = this.nextIndex;
            this.loadNextSlide();
        },
        prev() {
            this.currentIndex = this.previousIndex;
            this.loadPreviousSlide();
        },
        onOpenImage(slide) {
            window.open(slide.src);
        },
        onCopySuccess() {
            this.$flash('Image URL copied to clipboard!', 'green');
        },
        onCopyError() {
            this.$flash('Image URL failed to copy to clipboard', 'red');
        },
        onImageLoad(e) {
            if (e.target) {
                this.dimensions = `${e.target.naturalWidth} x ${e.target.naturalHeight}`;
            }
        },
        loadPreviousSlide() {
            const previousSlide = this.slides[this.previousIndex];
            if (!this.imagesLoaded.includes(previousSlide.src)) {
                const previousImage = new Image();
                previousImage.src = previousSlide.src;
                this.imagesLoaded.push(previousSlide.src);
            }
        },
        loadNextSlide() {
            const nextSlide = this.slides[this.nextIndex];
            if (!this.imagesLoaded.includes(nextSlide.src)) {
                const nextImage = new Image();
                nextImage.src = nextSlide.src;
                this.imagesLoaded.push(nextSlide.src);
            }
        }
    },

};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.9s ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;
    width:100%;
    opacity: 1;
}

.fade-enter,
.fade-leave-to {
    visibility: hidden;
    width:100%;
    opacity: 0;
}

.image-slider {
    position: relative;
    width: 100%;

    .prev, .next {
        cursor: pointer;
        position: absolute;
        height: 34px;
        width: 34px;
        top: 50%;
        margin-top: -16px;
        padding: 0;
        color: white;
        font-weight: bold;
        transition: opacity 0.5s ease;
        border-radius: 50%;
        text-decoration: none;
        user-select: none;
        background-color: black;
        opacity: 0.3;
        svg {
            vertical-align: middle;
        }
        &:hover {
            opacity: 0.6;
        }
    }
    .next {
        right: 10px;
        svg {
            margin: -2px -2px 0 0;
        }
    }

    .prev {
        left: 10px;
        svg {
            margin: -2px 0 0 -2px;
        }
    }
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .slide-contents {
        position: relative;
        width: 100%;
        height: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .slide-info {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;
            background-color: rgba(black, 0.2);
            color: white;
            display: flex;
            align-items: center;
            padding: 10px;
            .dimensions {
                margin-left: 20px;
            }
            .actions {
                margin-left: auto;
                button {
                    opacity: 0.8;
                    transition: opacity 0.5s ease;
                    margin-left: 6px;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
</style>
