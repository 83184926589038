import {
    //channels
    PLATFORM_FACEBOOK,
    PLATFORM_GOOGLE,
    PLATFORM_MICROSOFT,
    PLATFORM_PINTEREST,
    PLATFORM_TIKTOK,
    PLATFORM_SNAPCHAT,

    //keys
    FACEBOOK_KEY,
    GOOGLE_MARKETPLACE_KEY,
    GOOGLE_ADVERTISING_KEY,
    MICROSOFT_MARKETPLACE_KEY,
    MICROSOFT_ADVERTISING_KEY,
    PINTEREST_ADVERTISING_KEY,
    TIKTOK_ADVERTISING_KEY,
    SNAPCHAT_ADVERTISING_KEY,

    //category
    PRODUCT_CATEGORY_ADVERTISING_PLUS,

    //products
    PRODUCT_ADVERTISING_PLUS,
    PRODUCT_GOOGLE_MARKETPLACE,
    PRODUCT_GOOGLE_ADS_PLUS,
    PRODUCT_BING_MARKETPLACE,
    PRODUCT_MICROSOFT_MARKETPLACE_CPC,
    PRODUCT_MICROSOFT_ADS_PLUS,
    PRODUCT_PINTEREST_ADS_PLUS,
    PRODUCT_TIKTOK_ADS_PLUS,
    PRODUCT_SNAPCHAT_ADS_PLUS,

    //groups
    PRODUCT_GROUP_ADVERTISING,
    PRODUCT_GROUP_MARKETPLACE,
} from '@/helpers/globals';

export const inventoryChannels = [
    {
        channel: PLATFORM_FACEBOOK,
        channel_type: PRODUCT_GROUP_ADVERTISING,
        channel_name: 'Meta',
        valid_products: [PRODUCT_ADVERTISING_PLUS],
        valid_product_name: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: FACEBOOK_KEY
    },
    {
        channel: PLATFORM_GOOGLE,
        channel_type: PRODUCT_GROUP_MARKETPLACE,
        channel_name: 'Google (Marketplace)',
        valid_products: [PRODUCT_GOOGLE_MARKETPLACE],
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: GOOGLE_MARKETPLACE_KEY
    },
    {
        channel: PLATFORM_GOOGLE,
        channel_type: PRODUCT_GROUP_ADVERTISING,
        channel_name: 'Google (Advertising)',
        valid_products: [PRODUCT_GOOGLE_ADS_PLUS],
        valid_product_name: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: GOOGLE_ADVERTISING_KEY
    },
    {
        channel: PLATFORM_MICROSOFT,
        channel_type: PRODUCT_GROUP_MARKETPLACE,
        channel_name: 'Microsoft (Marketplace)',
        valid_products: [PRODUCT_BING_MARKETPLACE, PRODUCT_MICROSOFT_MARKETPLACE_CPC],
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: MICROSOFT_MARKETPLACE_KEY
    },
    {
        channel: PLATFORM_MICROSOFT,
        channel_type: PRODUCT_GROUP_ADVERTISING,
        channel_name: 'Microsoft (Advertising)',
        valid_products: [PRODUCT_MICROSOFT_ADS_PLUS],
        valid_product_name: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: MICROSOFT_ADVERTISING_KEY
    },
    {
        channel: PLATFORM_PINTEREST,
        channel_type: PRODUCT_GROUP_ADVERTISING,
        channel_name: 'Pinterest',
        valid_products: [PRODUCT_PINTEREST_ADS_PLUS],
        valid_product_name: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: PINTEREST_ADVERTISING_KEY
    },
    {
        channel: PLATFORM_TIKTOK,
        channel_type: PRODUCT_GROUP_ADVERTISING,
        channel_name: 'Tiktok',
        valid_products: [PRODUCT_TIKTOK_ADS_PLUS],
        valid_product_name: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: TIKTOK_ADVERTISING_KEY
    },
    {
        channel: PLATFORM_SNAPCHAT,
        channel_type: PRODUCT_GROUP_ADVERTISING,
        channel_name: 'Snapchat',
        valid_products: [PRODUCT_SNAPCHAT_ADS_PLUS],
        valid_product_name: PRODUCT_CATEGORY_ADVERTISING_PLUS,
        activated: false,
        unfinished: false,
        needs_upgrade: false,
        is_available: true,
        aggregation: SNAPCHAT_ADVERTISING_KEY
    },
]

export default {
    inventoryChannels
};

