<template>
    <div
        class="channel"
        :class="{
            'activated': activatedStyling,
            'upgrade': channel.needs_upgrade,
            'continue': channel.unfinished,
            'no-inventory': !hasInventory,
            'missing-features': incompleteFeatures.length,
            'not-eligible': !isEligible
        }">
        <div class="channel-activated">
            <span v-if="incompleteFeatures.length">Attention</span>
            <span v-else>Activated</span>
        </div>
        <div class="channel-content">
            <div class="channel-logo-wrapper">
                <img
                    v-if="channel.name == PLATFORM_FACEBOOK"
                    class="channel-logo"
                    :src="`/img/platforms/meta-blue.svg`">
                <img
                    v-else
                    class="channel-logo"
                    :src="`/img/platforms/${channel.name}.svg`">
            </div>
            <div class="channel-name">
                {{ capitalizeAndSwapFB(channel.name) }}
            </div>
            <div class="channel-type">
                {{ channel.channel_type.toUpperCase() }}
            </div>
            <div
                v-if="hasInventory"
                class="channel-total">
                {{ channel.available_vehicles }}
            </div>
            <div
                v-if="!hasInventory && isEligible"
                class="channel-total">
                -
            </div>
            <div
                v-if="!channel.activated && hasInventory"
                class="channel-eligible">
                Eligible Vehicles
            </div>
            <div
                v-if="!hasInventory && isEligible"
                class="channel-eligible">
                No Inventory
            </div>
        </div>
        <div
            v-if="channel.activated && hasInventory && isEligible"
            class="channel-nums">
            <div class="channel-num">
                <div class="channel-subtitle">
                    New
                </div>
                <div class="channel-subnum">
                    {{ channel.new_vehicles }}
                </div>
            </div>
            <div class="channel-num">
                <div class="channel-subtitle">
                    Used
                </div>
                <div class="channel-subnum">
                    {{ channel.used_vehicles }}
                </div>
            </div>
        </div>
        <div
            v-if="channel.activated && channel.filtered_reasons.length && isEligible && !incompleteFeatures.length && !loading"
            class="channel-filtered-wrapper">
            <styled-tooltip
                v-model="showFilteredDropdown"
                position="bottom"
                :high-index="true"
                :open-on-click="true"
                :no-padding="true"
                :rounded-border="true">
                <div @click="showFilteredDropdown = true">
                    <span class="channel-filtered">Filtered</span>
                    <span class="channel-filtered-number">{{ channel.filtered_vehicles }}</span>
                    <span class="channel-filtered-icon">
                        <icon
                            name="collapse-all"
                            color="#00a2ea"
                            size="12" />
                    </span>
                </div>
                <template #content>
                    <ul class="channel-dropdown">
                        <li
                            v-for="(reason, index) in channel.filtered_reasons"
                            :key="index"
                            @click.stop="applyFilter(reason.key)">
                            <span class="channel-dropdown-num">{{ reason.count }}</span>
                            <span class="channel-dropdown-desc">{{ reason.key }}</span>
                            <span class="channel-dropdown-view">view</span>
                        </li>
                    </ul>
                </template>
            </styled-tooltip>
        </div>
        <styled-loader
            v-if="loading"
            size="20" />

        <!-- channel is valid but not authenticated -->
        <button
            v-if="!channel.is_available && channel.authStatus == false"
            class="channel-button"
            @click="$emit('open-auth-modal', channel)">
            Activate
        </button>
        <!-- channel authenticated but not active -->
        <button
            v-else-if="!channel.is_available"
            class="channel-button"
            @click="$emit('open-product-dialog', channel.valid_products[0])">
            Activate
        </button>
        <!-- channel needs to be upgraded to the next version of product -->
        <button
            v-if="channel.needs_upgrade && checkUpgradeEligibility(channel)"
            class="channel-button"
            @click="$emit('open-product-dialog', channel.valid_products[0])">
            Upgrade
        </button>
        <!-- channel has active onboarding -->
        <button
            v-if="channel.unfinished"
            class="channel-button"
            @click="continueOnboarding(channel.unfinished_onboarding_id)">
            Continue
        </button>
        <!-- channel has incomplete features -->
        <button
            v-if="incompleteFeatures.length && !loading"
            class="channel-button"
            @click="resolve(channel)">
            Resolve
        </button>

        <div
            v-if="channel.needs_upgrade"
            class="channel-additional">
            <a
                v-if="checkUpgradeEligibility(channel)"
                href="#"
                @click.prevent="$emit('open-product-dialog', channel.valid_products[0])">Advertising Plus</a>
            <span v-else>Advertising Plus</span>
            upgrade is needed to advertise inventory
        </div>
        <div
            v-if="channel.unfinished"
            class="channel-additional">
            Complete onboarding to activate this channel
        </div>
        <div
            v-if="incompleteFeatures.length"
            class="channel-additional">
            There's an issue that<br> needs your attention
        </div>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';
import StyledLoader from '@/components/globals/StyledLoader';
import { productData } from '@/components/onboarding/workflow/store/getters';
import { canUpgradeChannel } from '@/components/onboarding/channel-setup/channels';
import { capitalizeAndSwapFB } from '@/helpers/capitalizeAndSwapFB';
import { PLATFORM_FACEBOOK } from '@/helpers/globals';
import { mapState } from 'vuex';

export default {
    components: {
        StyledTooltip,
        StyledLoader,
        Icon,
    },
    props: {
        channel: {
            type: Object,
            required: true
        },
        hasInventory: {
            type: Boolean,
            required: true
        },
        isEligible: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            showFilteredDropdown: false,
            upperPosition: 0,
            lowerPosition: 0,
            activatedStyling: false,
            requiredFeatures: [],
            loading: false,
            PLATFORM_FACEBOOK,
            capitalizeAndSwapFB
        };
    },
    computed: {
        ...mapState({
            dealer: state => state.dealer.currentDealer,
            featureStatus: state => state.dealer.featureStatus,
        }),
        incompleteFeatures() {
            return this.requiredFeatures.filter(feature => feature.status !== 'complete');
        },
    },
    watch: {
        showFilteredDropdown() {
            if (this.showFilteredDropdown === true) {
                this.lowerPosition = window.scrollY + 100;
                this.upperPosition = window.scrollY - 100;
                window.addEventListener('scroll', this.hideTooltipOnScroll);
            } else {
                window.removeEventListener('scroll', this.hideTooltipOnScroll);
            }
        }
    },
    async mounted() {
        // check if channel has all required features!
        if (this.channel.activated) {
            this.loading = true;
            const validProduct = this.channel.products.filter(product => this.channel.valid_products.includes(product.id));
            const currentProductFeatures = productData[validProduct[0].id];
            await this.checkFeatureStatus(currentProductFeatures.requiredFeatures);
            this.requiredFeatures = this.featureStatus.filter(f => currentProductFeatures.requiredFeatures.includes(f.feature));
            this.loading = false;
            // adding css-styles only after features checking is complete
            this.activatedStyling = true;
        }
    },
    methods: {
        async checkFeatureStatus(channelFeatures) {
            await this.$store.dispatch('updateDealerFeatureStatus', {
                features: channelFeatures
            });
        },
        async resolve(channel) {
            this.loading = true;
            if (channel.unfinished_onboarding_id) {
                this.continueOnboarding(channel.unfinished_onboarding_id);
            } else {
                await this.$store.dispatch('startNewOnboarding', {
                    dealerId: this.dealer.id,
                    productIds: [channel.products[0].id],
                    redirectUrl: this.$route.fullPath
                });
            }
            this.loading = false;
        },
        hideTooltipOnScroll() {
            const currentPosition = window.scrollY;
            if (currentPosition < this.upperPosition || currentPosition > this.lowerPosition) {
                this.showFilteredDropdown = false;
            }
        },
        applyFilter(filter) {
            this.$emit('apply-filtering', filter);
            this.showFilteredDropdown = false;
        },
        continueOnboarding(id) {
            this.$router.push({
                name: 'dealer-onboarding',
                params: {
                    dealer_id: this.dealer.id,
                    onboarding_id: id
                }
            });
        },
        checkUpgradeEligibility(channel) {
            const enrolledProducts = channel.products;
            const enrolledProductIds = enrolledProducts.map(product => product.id);
            return canUpgradeChannel(channel.id, enrolledProductIds);
        },
    }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
.channel {
    vertical-align: top;
    display: inline-block;
    position: relative;
    padding: 15px;
    margin-bottom: 45px;
    margin-top: 45px;
    margin-right: 22.5px;
    text-align: center;
    min-height: 255px;
    width: 140px;
    background-color: #FAFBFC;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2303A2EAFF' stroke-width='3' stroke-dasharray='5%2c 7' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 8px;
}
.channel:not(.no-inventory) {
    &:last-of-type {
        margin-right: 0 !important;
    }
}
.channel.activated {
    background-image: none;
    outline: 4px solid $blue-bg;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 136px;
    min-height: 253px;
    margin-right: 26.5px;
    .channel-activated {
        display: block;
    }
    .channel-content, .channel-nums {
        opacity: 1;
    }
}
.channel.upgrade, .channel.missing-features {
    .channel-button {
        background: #EA8202;
    }
}
.channel.continue {
    background: none;
    border: 1px solid $blue-bg;
    .channel-button {
        background: #03A2EA;
    }
    .channel-content, .channel-nums {
        opacity: 1;
    }
}
.channel-content, .channel-nums {
    opacity: 0.5;
}
.channel.no-inventory {
    margin-top: 20px;
    min-height: 160px;
}
.channel.not-eligible {
    .channel-button {
        margin-top: 15px;
    }
}
.channel.activated.no-inventory {
    min-height: 253px;
}
.channel.activated.no-inventory.not-eligible {
    min-height: 160px;
}
.channel-activated {
    display: none;
    position: absolute;
    bottom: calc(100% - 1px);
    left: -4px;
    right: -4px;
    background: #03A2EA;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 5px 0;
}
.channel.missing-features {
    outline: 4px solid #EA8202;
    padding-bottom: 5px;
}
.channel.missing-features .channel-button {
    margin-top: 5px;
}
.channel.missing-features .channel-activated {
    background: #EA8202;
}
.channel-logo-wrapper {
    width: 30px;
    height: 30px;
    display: table;
    margin: 0 auto;
}
.channel-logo {
    width: 100%;
    height: auto;
    max-width: 30px;
    max-height: 30px;
}
.channel-name {
    color: $gray;
    font-size: 12px;
}
.channel-type {
    text-transform: uppercase;
    margin-top: 15px;
    font-size: 12px;
}
.channel-total {
    font-size: 33px;
    font-weight: 700;
    color: $blue-bg;
    font-family: 'Lato', sans-serif;
}
.channel-eligible {
    text-transform: uppercase;
    font-size: 9px;
    margin-top: 5px;
}
.channel-nums {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}
.channel-subtitle {
    font-size: 9px;
    text-transform: uppercase;
}
.channel-subnum {
    font-size: 20px;
    font-weight: 700;
    color: $blue-bg;
    font-family: 'Lato', sans-serif;
}
.channel-button {
    font-size: 14px;
    color: $white;
    background: #7ED321;
    padding: 2px 15px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 6px;
}
.channel-filtered-wrapper {
    cursor: pointer;
    margin-top: 5px;
}
.channel-filtered {
    font-size: 11px;
    color: $gray;
}
.channel-filtered-number {
    font-size: 12px;
    color: $blue-bg;
    text-decoration: underline;
    margin-left: 5px;
}
.channel-filtered-icon {
    display: inline-block;
    margin-left: 5px;
    transform: rotate(180deg) translateY(-7px);
}
.channel-dropdown {
    position: relative;
    list-style-type: none;
    padding: 10px 0;
    li {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        &:nth-child(even) {
            background-color: #F8F8F8;
        }
        &:hover {
            background-color: $alabaster-dark;
        }
    }
}
.channel-dropdown-num {
    display: inline-block;
    font-size: 11px;
    color: $blue-bg;
    margin-right: 10px;
    font-weight: 700;
    width: 20px;
}
.channel-dropdown-desc {
    width: calc(100% - 60px);
}
.channel-dropdown-view {
    color: $blue-bg;
    width: 40px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    justify-content: flex-end;
}
.channel-additional {
    position: absolute;
    left: -5px;
    right: -5px;
    top: calc(100% + 5px);
    font-size: 10px;
    text-align: center;
}
</style>