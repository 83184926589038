<template>
    <div
        id="inventory-dashboard"
        class="inventory-table">
        <vehicle-details-slideout
            ref="vehicleDetailsSlideout"
            :vehicle-id="vehicleId"
            :value="vehicleDetailsOpened"
            :show-description="showDescription"
            @close="closeVehicleDetails" />
        <catalog-exports-slideout
            :catalog-exports="catalogExports"
            :value="catalogExportsOpened"
            :loading="exportsLoading"
            :has-inventory="hasInventory"
            @input="closeCatalogExports" />
        <styled-interface hide-toolbar>
            <template #interface-heading>
                <div class="ml-1 main-title">
                    Inventory
                </div>
            </template>
            <styled-card class="mb-4">
                <template #heading>
                    <div class="heading-wrapper">
                        <div>Inventory Details</div>
                    </div>
                </template>
                <template
                    v-if="isEligible && !refreshing"
                    #action-buttons>
                    <!-- <action-button
                        icon="download"
                        :loading="inventoryExportLoading"
                        @click="exportCSV()">
                        Export CSV
                    </action-button> -->
                    <action-button
                        icon="refresh"
                        @click="refresh()">
                        Refresh
                    </action-button>
                    <action-button
                        class="catalog-exports-icon"
                        icon="upload"
                        @click="openCatalogExports()">
                        Catalog Exports
                    </action-button>
                    <action-button
                        icon="data"
                        @click="scrollToBottom()">
                        Data Sources
                    </action-button>
                </template>
                <loader v-if="refreshing" />
                <template v-else>
                    <inventory-analysis
                        v-if="isEligible"
                        :has-inventory="hasInventory"
                        :loading="refreshing"
                        :used-count="usedCount"
                        :new-count="newCount" />
                    <channel-activation
                        :aggregations="aggregations"
                        :has-inventory="hasInventory"
                        :is-eligible="isEligible"
                        @apply-filtering="applyFiltering" />
                    <inventory-ads v-if="isEligible" />
                    <div
                        v-if="isEligible"
                        class="mt-5">
                        <custom-table
                            ref="inventoryTable"
                            :items="inventory"
                            :fields="fields"
                            :loading="loading"
                            :pagination="pagination"
                            :rows-per-page="pagination.rowsPerPage"
                            :total-items="pagination.meta.totalItems"
                            :notification="notification"
                            :notification-type="notificationType"
                            :hide-search="false"
                            :hide-sort="true"
                            :has-filters="true"
                            :has-customization="true"
                            :backend-filtering="true"
                            :slim-cells="true"
                            :custom-filters="allFilters"
                            :active-reasons="activeReasons"
                            :active-channels="activeChannels"
                            :selected-filters="selectedFilters"
                            :has-inventory="hasInventory"
                            name="inventory"
                            min-width="1400px"
                            @update-pagination="updatePagination"
                            @update-filtering="updateFiltering"
                            @search="onSearch">
                            <template #header-slot>
                                <div
                                    v-if="activeReasons.length || activeChannels.length || selectedTags.length"
                                    class="inventory-filters">
                                    <div
                                        class="filter-title">
                                        Filtered by:
                                    </div>
                                    <div class="filter-applied">
                                        <div
                                            v-if="activeReasons.length"
                                            class="filter-single">
                                            <span>Filtered Reasons</span>
                                            <div @click="clearSelectedReasons">
                                                <icon
                                                    name="close"
                                                    color="#8f9ea6"
                                                    size="10"
                                                    @click="clearSelectedReasons" />
                                            </div>
                                        </div>
                                        <div
                                            v-if="activeChannels.length"
                                            class="filter-single">
                                            <span>Filtered Channels</span>
                                            <div @click="clearSelectedChannels">
                                                <icon
                                                    name="close"
                                                    color="#8f9ea6"
                                                    size="10"
                                                    @click="clearSelectedChannels" />
                                            </div>
                                        </div>
                                        <template v-if="selectedTags.length">
                                            <div
                                                v-for="(tag, index) in selectedTags"
                                                :key="index"
                                                class="filter-single">
                                                <span>{{ tag }}</span>
                                                <div @click="clearTag(tag)">
                                                    <icon
                                                        name="close"
                                                        color="#8f9ea6"
                                                        size="10"
                                                        @click="clearTag(tag)" />
                                                </div>
                                            </div>
                                        </template>
                                        <div
                                            class="filter-clear"
                                            @click="clearAllSelections">
                                            Clear all
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </custom-table>
                    </div>
                </template>
            </styled-card>
            <!-- DATA SOURCES -->
            <data-sources
                v-if="isEligible"
                id="data-sources"
                @refresh-inventory="refresh()" />
        </styled-interface>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledCard from '@/components/globals/StyledCard';
import StyledInterface from '@/components/globals/StyledInterface';
import ActionButton from '@/components/globals/ActionButton';
import VehicleDetailsSlideout from '@/components/inventory-dashboard/VehicleDetailsSlideout';
import CatalogExportsSlideout from '@/components/inventory-dashboard/CatalogExportsSlideout';
import InventoryAnalysis from '@/components/inventory-dashboard/InventoryAnalysis';
import ChannelActivation from '@/components/inventory-dashboard/ChannelActivation';
import DataSources from '@/components/globals/data-sources/DataSources';
import InventoryAds from '@/components/inventory-dashboard/InventoryAds';
import Loader from '@/components/globals/Loader';
//import exportToCsv from '@/helpers/exportToCsv';
import { inventoryChannels } from '@/helpers/inventory-dashboard/channels';
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce, isEqual } from 'lodash';
import { FEATURE_INVENTORY } from '@/helpers/globals';

export default {
    name: 'InventoryDashboard',
    title: 'Inventory Dashboard',
    components: {
        Icon,
        Loader,
        CustomTable,
        StyledCard,
        StyledInterface,
        ActionButton,
        VehicleDetailsSlideout,
        CatalogExportsSlideout,
        InventoryAnalysis,
        ChannelActivation,
        DataSources,
        InventoryAds
    },
    data() {
        return {
            vehicleDetailsOpened: false,
            catalogExportsOpened: false,
            catalogExports: [],
            exportsLoading: false,
            vehicleId: null,
            loading: true,
            refreshing: true,
            usedCount: 0,
            newCount: 0,
            aggregations: null,
            hasInventory: false,
            isEligible: false,
            showDescription: false,
            allFilters: [],
            currentFilters: {},
            filteredCondition: [],
            filterFields: [
                'year',
            ],
            // list of fields that need to have '.keyword' ending when working with back-end API...
            filterFieldsWithKeyword: [
                'condition',
                'vin',
                'stock_number',
                'make_name',
                'model_name',
                'trim_name',
                'body_name',
                'fuel_type_name',
                'drivetrain',
                'engine',
                'features',
                'description',
                'date_first_on_lot',
                'custom_label_0',
                'custom_label_1',
                'custom_label_2',
                'transmission',
                'vehicle_type_name'
            ],
            fields: [
                {
                    id: 'vin',
                    header: 'VIN #',
                    value: 'vin',
                    align: 'left',
                    width: '170px',
                    sortable: true,
                    filter: 'search',
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/VehicleDetails.vue'),
                    callback: (value) => {
                        this.openVehicleDetails(value);
                    },
                    props: {
                        itemType: 'vin'
                    },
                    default: true,
                },
                {
                    id: 'stock_number',
                    header: 'Stock #',
                    value: 'stock_number',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    filter: 'search',
                    type: 'single',
                    default: true,
                },
                {
                    id: 'year',
                    header: 'Year',
                    value: 'year',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: true,
                },
                {
                    id: 'condition',
                    header: 'Condition',
                    value: 'condition',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: true,
                },
                {
                    id: 'make_name',
                    header: 'Make',
                    value: 'make_name',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: true,
                },
                {
                    id: 'model_name',
                    header: 'Model',
                    value: 'model_name',
                    align: 'left',
                    width: '130px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: true,
                },
                {
                    id: 'trim_name',
                    header: 'Trim',
                    value: 'trim_name',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    filter: 'search',
                    type: 'single',
                    default: true,
                },
                {
                    id: 'body_name',
                    header: 'Body',
                    value: 'body_name',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: true,
                },
                {
                    id: 'msrp',
                    header: 'MSRP',
                    value: 'msrp',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                    default: true,
                },
                {
                    id: 'price',
                    header: 'Price',
                    value: 'price',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                    default: true,
                },
                {
                    id: 'filtered_channel',
                    header: 'Filtered Channel',
                    value: 'filtered_channel',
                    align: 'left',
                    width: '150px',
                    sortable: false,
                    filter: 'multiple',
                    placeholder: '',
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/FilteredChannels.vue'),
                    default: true,
                },
                {
                    id: 'filtered_reason',
                    header: 'Filtered Reason',
                    value: 'filtered_reason',
                    align: 'left',
                    width: '150px',
                    sortable: false,
                    filter: 'multiple',
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/FilteredReasons.vue'),
                    default: true,
                },
                {
                    id: 'vdp',
                    header: 'VDP',
                    value: 'vdp',
                    align: 'left',
                    width: '80px',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/InventoryLink.vue'),
                    props: {
                        linkType: 'link'
                    },
                    default: true,
                },
                {
                    id: 'details',
                    header: 'Details',
                    value: 'details',
                    align: 'left',
                    width: '80px',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/VehicleDetails.vue'),
                    callback: (value) => {
                        this.openVehicleDetails(value);
                    },
                    default: true,
                },
                {
                    id: 'fuel_type_name',
                    header: 'Fuel Type',
                    value: 'fuel_type_name',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'mileage',
                    header: 'Mileage',
                    value: 'mileage',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    type: 'single',
                    default: false,
                },
                {
                    id: 'drivetrain',
                    header: 'Drivetrain',
                    value: 'drivetrain',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'engine',
                    header: 'Engine',
                    value: 'engine',
                    align: 'left',
                    width: '180px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'sale_price',
                    header: 'Sale Price',
                    value: 'sale_price',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    type: 'single',
                    default: false,
                },
                {
                    id: 'mpg_city',
                    header: 'MPG (City)',
                    value: 'mpg_city',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    type: 'single',
                    default: false,
                },
                {
                    id: 'mpg_highway',
                    header: 'MPG (Highway)',
                    value: 'mpg_highway',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    type: 'single',
                    default: false,
                },
                {
                    id: 'features',
                    header: 'Features',
                    value: 'features',
                    align: 'left',
                    width: '250px',
                    sortable: false,
                    filter: 'search',
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/VehicleDetails.vue'),
                    callback: (value) => {
                        this.openVehicleDetails(value, false, true);
                    },
                    props: {
                        itemType: 'features'
                    },
                    default: false,
                },
                {
                    id: 'description',
                    header: 'Description',
                    value: 'description',
                    align: 'left',
                    width: '250px',
                    sortable: false,
                    filter: 'search',
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/VehicleDetails.vue'),
                    callback: (value) => {
                        this.openVehicleDetails(value, false, true);
                    },
                    props: {
                        itemType: 'description'
                    },
                    default: false,
                },
                {
                    id: 'date_first_on_lot',
                    header: 'Date on Lot',
                    value: 'date_first_on_lot',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    type: 'single',
                    default: false,
                },
                {
                    id: 'days_on_lot',
                    header: 'Days On Lot',
                    value: 'days_on_lot',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    type: 'single',
                    default: false,
                },
                {
                    id: 'created_at',
                    header: 'Date in BuyerBridge',
                    value: 'created_at',
                    align: 'left',
                    width: '150px',
                    sortable: true,
                    type: 'single',
                    default: false,
                },
                {
                    id: 'custom_label_0',
                    header: 'Custom 0',
                    value: 'custom_label_0',
                    align: 'left',
                    width: '110px',
                    sortable: false,
                    filter: 'search',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'custom_label_1',
                    header: 'Custom 1',
                    value: 'custom_label_1',
                    align: 'left',
                    width: '110px',
                    sortable: false,
                    filter: 'search',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'custom_label_2',
                    header: 'Custom 2',
                    value: 'custom_label_2',
                    align: 'left',
                    width: '110px',
                    sortable: false,
                    filter: 'search',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'transmission',
                    header: 'Transimission',
                    value: 'transmission',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'vehicle_type_name',
                    header: 'Vehicle Type',
                    value: 'vehicle_type_name',
                    align: 'left',
                    width: '120px',
                    sortable: true,
                    filter: 'multiple',
                    placeholder: 'All',
                    type: 'single',
                    default: false,
                },
                {
                    id: 'images',
                    header: 'Image',
                    value: 'images',
                    align: 'left',
                    width: '110px',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/inventory-dashboard/InventoryImage.vue'),
                    callback: (value) => {
                        this.openVehicleDetails(value, true);
                    },
                    default: false,
                },
            ],
            inventory: [],
            raw: [],
            pagination: {
                rowsPerPage: 50,
                page: 1,
                sortBy: '',
                descending: true,
                meta: {
                    totalItems: null,
                    current_page: null,
                    last_page: null,
                    to: null,
                    from: null,
                }
            },
            search: '',
            notification: '',
            notificationType: '',
            inventoryExport: [],
            inventoryExportLoading: false,
            activatedChannelsList: [],
            filteredReasons: [],
            filteredReasonsDetailed: {},
            activeReasons: [],
            activeChannels: [],
            filteredChannels: [],
            selectedFilters: {},
            selectedTags: []
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
            activatedChannels: state => state.settings.activatedChannels,
        }),
        ...mapGetters([
            'dealerHasFeature',
        ]),
    },
    watch: {
        currentDealerId() {
            this.getInventory(false);
        },
        activatedChannels() {
            // get new filters only if channels have changed to avoid multiple calls
            if (!isEqual(this.activatedChannels, this.activatedChannelsList)) {
                this.getFiltersAndAggregations();
                this.activatedChannelsList = this.activatedChannels;
            }
        }
    },
    async created() {
        await this.waitForDealerLoaded();
        this.checkInventoryStatus();
        await this.getFiltersAndAggregations();
        await this.getInventory(false);
        this.refreshing = false;
    },
    activated() {
        document.body.classList.add('inventory-dashboard-page');
    },
    deactivated() {
        document.body.classList.remove('inventory-dashboard-page');
    },
    methods: {
        ...mapActions([
            'waitForDealerLoaded'
        ]),
        clearSelectedReasons() {
            this.filteredReasons = [];
            this.activeReasons = [];
            this.updateFiltering([], 'filtered_reason');
        },
        clearSelectedChannels() {
            this.filteredChannels = [];
            this.activeChannels = [];
            this.updateFiltering([], 'filtered_channel');
        },
        clearTag(tag) {
            const currentTag = this.fields.find(field => field.header == tag);
            this.selectedFilters[currentTag.id] = [];
            this.populateTags();
            this.updateCurrentFilters(currentTag.id);
            this.getInventory(true, this.currentFilters, 1);
        },
        clearAllSelections() {
            this.filteredReasons = [];
            this.activeReasons = [];
            this.filteredChannels = [];
            this.activeChannels = [];
            this.filteredCondition = [];
            for (const filter in this.selectedFilters) {
                this.selectedFilters[filter] = [];
                this.updateCurrentFilters(filter);
            }
            this.populateTags();
            this.getInventory(true, this.currentFilters, 1);
        },
        updateCurrentFilters(filter) {
            let filterName = filter;
            const needsEnding = this.filterFieldsWithKeyword.find(i => i == filter);
            if (needsEnding) filterName = filter+'.keyword';
            const filterObj = {
                [filterName]: []
            };
            Object.assign(this.currentFilters, filterObj);
        },
        checkInventoryStatus() {
            if (this.dealerHasFeature(FEATURE_INVENTORY)) {
                this.isEligible = true;
            } else {
                this.isEligible = false;
            }
        },
        applyFiltering(filter) {
            this.filteredReasons = [];
            this.activeReasons = [];
            this.filteredReasons.push(filter);
            this.activeReasons.push(filter);
            this.getInventory(true, [], 1);
        },
        openVehicleDetails(details, openGallery, goToBottom) {
            this.vehicleDetailsOpened = true;
            this.vehicleId = details.vehicle_id;
            if (openGallery) {
                this.$refs.vehicleDetailsSlideout.openVehicleGallery();
            }
            if (goToBottom) {
                this.showDescription = true;
            } else {
                this.showDescription = false;
            }
        },
        closeVehicleDetails(value) {
            this.vehicleDetailsOpened = value;
        },
        closeCatalogExports(value) {
            this.catalogExportsOpened = value;
        },
        async openCatalogExports() {
            this.catalogExports = [];
            this.catalogExportsOpened = true;
            this.exportsLoading = true;
            try {
                const response = await this.$http.get(`/dealers/${this.currentDealerId}/get-remote-files`);
                this.catalogExports = response.data;
            } catch(error) {
                console.log(error);
            } finally {
                this.exportsLoading = false;
            }
        },
        async refresh() {
            this.refreshing = true;
            await this.$nextTick();
            this.search = '';
            this.filteredCondition = [];
            this.currentFilters = {};
            this.inventory = [];
            this.pagination = {
                rowsPerPage: 50,
                page: 1,
                sortBy: '',
                descending: true,
                meta: {
                    totalItems: null,
                    current_page: null,
                    last_page: null,
                    to: null,
                    from: null,
                }
            };
            await this.getFiltersAndAggregations();
            await this.getInventory(false);
            this.refreshing = false;
        },
        onSearch: debounce(async function(search) {
            if (search) {
                this.search = search;
            } else {
                this.search = '';
            }
            await this.getInventory(true, this.currentFilters, 1);
        }, 300),
        async updatePagination(pagination) {
            Object.assign(this.pagination, pagination);
            await this.getInventory(true, this.currentFilters);
        },
        async getFiltersAndAggregations() {
            try {
                this.loading = true;
                // eslint-disable-next-line
                const filterFields = structuredClone(this.filterFields);
                this.filterFieldsWithKeyword.forEach(field => {
                    filterFields.push(field+'.keyword');
                });
                const inventoryData = {
                    filters: {
                        dealer_id: this.currentDealerId,
                    },
                    aggregations: [
                        'used',

                        // get eligible and new/used counts for EACH channel
                        { 'exports.facebook_auto.is_vehicle_eligible': ['used'] },
                        { 'exports.google_marketplace.is_vehicle_eligible': ['used'] },
                        { 'exports.google_auto.is_vehicle_eligible': ['used'] },
                        { 'exports.microsoft_marketplace.is_vehicle_eligible': ['used'] },
                        { 'exports.microsoft_auto.is_vehicle_eligible': ['used'] },
                        { 'exports.pinterest_auto.is_vehicle_eligible': ['used'] },
                        { 'exports.tiktok_auto.is_vehicle_eligible': ['used'] },
                        { 'exports.snapchat_auto.is_vehicle_eligible': ['used'] },

                        // get filtered reasons for EACH channel
                        'exports.facebook_auto.filtered_vehicle_reasons.reason.keyword',
                        'exports.google_marketplace.filtered_vehicle_reasons.reason.keyword',
                        'exports.google_auto.filtered_vehicle_reasons.reason.keyword',
                        'exports.microsoft_marketplace.filtered_vehicle_reasons.reason.keyword',
                        'exports.microsoft_auto.filtered_vehicle_reasons.reason.keyword',
                        'exports.pinterest_auto.filtered_vehicle_reasons.reason.keyword',
                        'exports.tiktok_auto.filtered_vehicle_reasons.reason.keyword',
                        'exports.snapchat_auto.filtered_vehicle_reasons.reason.keyword',

                        ...filterFields
                    ]
                };
                const response = await this.$apiRepository.getInventoryVehicles(inventoryData);
                const newCount = response.data.aggregations.used.filter(a => a.key == 'false')[0];
                const usedCount = response.data.aggregations.used.filter(a => a.key == 'true')[0];
                this.newCount = newCount?.count ?? 0;
                this.usedCount = usedCount?.count ?? 0;
                this.aggregations = response.data.aggregations;
                // eslint-disable-next-line
                const fields = structuredClone(this.filterFields);
                const filters = [
                    {
                        condition: [
                            'New',
                            'Used',
                            'Certified',
                        ]
                    }
                ];
                this.filterFieldsWithKeyword.forEach(field => {
                    fields.push(field+'.keyword');
                });
                // get regular filters
                fields.forEach(field => {
                    const fieldValues = [];
                    response.data.aggregations[field].forEach(f => {
                        if (f.key) {
                            fieldValues.push(f.key);
                        }
                    });
                    const current = {
                        [field.replace('.keyword', '')]: fieldValues.sort()
                    };
                    filters.push(current);
                });
                // populate filtered reasons and channels
                const allReasons = new Set();
                const channelFilters = [];
                this.filteredReasonsDetailed = {};
                this.activatedChannels.forEach(channel => {
                    const fullPath = `exports.${channel}.filtered_vehicle_reasons.reason.keyword`;
                    const currentReasons = [];
                    response.data.aggregations[fullPath].forEach(f => {
                        if (f.key) {
                            allReasons.add(f.key);
                            currentReasons.push(f.key);
                        }
                    });
                    const channelReason = {
                        [channel]: currentReasons
                    };
                    Object.assign(this.filteredReasonsDetailed, channelReason);

                    const currentChannel = inventoryChannels.find(c => c.aggregation === channel);
                    channelFilters.push(currentChannel.channel_name);
                });
                const filteredReason = {
                    'filtered_reason': Array.from(allReasons)
                };
                const filteredChannel = {
                    'filtered_channel': channelFilters
                };
                filters.push(filteredReason);
                filters.push(filteredChannel);
                this.allFilters = filters;
                this.allFilters.forEach(filter => {
                    if (Object.keys(filter)[0] !== 'filtered_channel' && Object.keys(filter)[0] !== 'filtered_reason') {
                        const selectedFilter = {
                            [Object.keys(filter)[0]]: []
                        };
                        Object.assign(this.selectedFilters, selectedFilter);
                    }
                });
            } catch(error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        updateFiltering(value, filter) {
            let filterName = filter;
            const needsEnding = this.filterFieldsWithKeyword.find(i => i == filter);
            if (needsEnding) filterName = filter+'.keyword';
            if (filter == 'condition') {
                this.filteredCondition = value;
                this.selectedFilters['condition'] = value;
                this.populateTags();
            } else if (filter == 'filtered_reason') {
                this.filteredReasons = value;
                this.activeReasons = value;
            } else if (filter == 'filtered_channel') {
                // convert from user friendly naming to back-end acceptable
                // example 'Microsoft (Marketplace)' => 'microsoft_marketplace'
                const acceptableChannels = [];
                value.forEach(channel => {
                    const currentChannel = inventoryChannels.find(c => c.channel_name === channel);
                    acceptableChannels.push(currentChannel.aggregation);
                });
                this.filteredChannels = acceptableChannels;
                this.activeChannels = value;
            } else {
                this.selectedFilters[filter] = value;
                const filterObj = {
                    [filterName]: value
                };
                Object.assign(this.currentFilters, filterObj);
                this.populateTags();
            }
            this.getInventory(true, this.currentFilters, 1);
        },
        populateTags() {
            const tags = new Set();
            for (const filter in this.selectedFilters) {
                const currentFilter = this.fields.find(field => field.id == filter);
                if (this.selectedFilters[filter].length) {
                    tags.add(currentFilter.header);
                } else {
                    tags.delete(currentFilter.header);
                }
            }
            this.selectedTags = Array.from(tags);
        },
        async getInventory(scrollToTable, filters, page) {
            try {
                this.notification = '';
                this.notificationType = 'info';
                this.loading = true;
                if (page) {
                    this.pagination.page = page;
                }

                // certified, new and used filtering has different logic than other filters
                let certified = null;
                let used = null;
                if (this.filteredCondition.find(c => c == 'Certified')) certified = true;
                if (this.filteredCondition.find(c => c == 'Used')) used = true;
                if (this.filteredCondition.find(c => c == 'New')) used = false;
                if (this.filteredCondition.find(c => c == 'New') && this.filteredCondition.find(c => c == 'Used')) used = null;

                const inventoryData = {
                    q: this.search,
                    filters: {
                        'dealer_id': this.currentDealerId,
                        certified,
                        used,
                        'exports.all.filtered_catalogs.keyword': this.filteredChannels,
                        'exports.all.filtered_vehicle_reasons.reason.keyword': this.filteredReasons,
                        ...filters
                    },
                    aggregations: [],
                    page: this.pagination.page,
                    page_size: this.pagination.rowsPerPage || this.pagination.totalItems
                };
                if (this.pagination.sortBy) {
                    const needsEnding = this.filterFieldsWithKeyword.find(i => i == this.pagination.sortBy);
                    const order = {
                        order: {
                            field: needsEnding ? this.pagination.sortBy+'.keyword': this.pagination.sortBy,
                            direction: this.pagination.descending ? 'DESC' : 'ASC'
                        }
                    };
                    Object.assign(inventoryData, order);
                }
                const response = await this.$apiRepository.getInventoryVehicles(inventoryData);
                this.inventory = response.data.data;
                if (this.inventory.length) {
                    this.hasInventory = true;
                } else {
                    this.hasInventory = false;
                }
                // format price and condition
                this.inventory.forEach(item =>{
                    item.price = this.$root.$options.filters.formatCurrency(item.price);
                    if (item.certified) {
                        item.condition = 'CPO';
                    } else if (item.used) {
                        item.condition = 'Used';
                    } else {
                        item.condition = 'New';
                    }
                });
                this.raw = response.data;

                // adding all pagination meta after we have response
                const newpagination = { ...this.pagination };
                newpagination.meta.totalItems = this.raw.meta.total;
                newpagination.meta.current_page = this.raw.meta.current_page;
                newpagination.meta.last_page = this.raw.meta.last_page;
                newpagination.meta.to = this.raw.meta.to;
                newpagination.meta.from = this.raw.meta.from;
                this.pagination = newpagination; // setting new pagination and triggering 'watch' to re-render table-component
                this.loading = false;
                if (scrollToTable) this.scrollToTable();
            } catch (error) {
                console.log('Error retrieving inventory', error);
                this.loading = false;
                this.notification = error + '!';
                this.notificationType = 'error';
            }
        },
        scrollToTable() {
            window.scrollTo({ top: this.$refs.inventoryTable.$el.offsetTop + 100, left: 0, behavior: 'smooth' });
        },
        scrollToBottom() {
            window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
        },
        /*async exportCSV() {
            // If there's no vehicles there's nothing to do
            if (!this.inventory.length) {
                return;
            }
            try {
                // Reset the leads export
                this.inventoryExport = [];
                this.inventoryExportLoading = true;
                let complete = false;
                let page = 1;
                let response;
                // Continue until complete
                while (!complete) {
                    await this.getInventory(false, page);
                    response = this.raw;
                    this.inventoryExport = this.inventoryExport.concat(response.data);
                    // Load the require until the current page equals the last page
                    if (response.meta.current_page === response.meta.last_page) {
                        complete = true;
                    }
                    // Increment the page
                    page++;
                }
                // Prep for export
                this.inventoryExport = this.inventoryExport.map(vehicle => {
                    vehicle.created_at = this.$moment.utc(vehicle.created_at.date).local().format('YYYY-MM-DD hh:mm:ss') || '';
                    const dealerVehicle = get(vehicle, 'dealer_vehicle.data[0]', null) || {};
                    vehicle = { ...vehicle, ...dealerVehicle };
                    delete vehicle.dealer_vehicle;
                    // Change null values to empty strings
                    for (let field in vehicle) {
                        if (vehicle[field] === null) {
                            vehicle[field] = '';
                        }
                    }
                    return vehicle;
                });
                exportToCsv(`inventory_${new Date().getTime()}`, this.inventoryExport);
            } catch (error) {
                // @todo show error
                console.error(error);
            } finally {
                this.inventoryExportLoading = false;
            }
        },*/
    },
};
</script>

<style lang="scss">
// css scoped only for inventory-dashboard
#inventory-dashboard {
    .main-title {
        transform: translateY(10px);
    }
    .catalog-exports-icon .action-button-button {
        transform: scale(1.3);
    }
    a {
        color: $blue-bg;
    }
    .inventory-filters {
        margin-top: -20px;
    }
    .filter-title{
        color: #00A4EB;
        margin-bottom: 5px;
    }
    .filter-applied{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
    }
    .filter-single{
        display: flex;
        align-items: center;
        background: #D7F0FB;
        border-radius: 25px;
        padding: 5px 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        span{
            margin-right: 15px;
        }
        div{
            display: flex;
            align-items: center;
            margin-top:2px;
            cursor: pointer;
        }
    }
    .filter-clear{
        color: #00A4EB;
        text-decoration: underline;
        cursor: pointer;
    }
}
#data-sources {
    margin: 60px 0;
}
.inventory-dashboard-page .v-menu__content.custom-table-menu {
    z-index: 99 !important;
}
</style>
