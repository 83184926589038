<template>
    <vehicle
        :id="vehicleId"
        ref="vehicle"
        :value="value"
        :show-description="showDescription"
        @input="close" />
</template>

<script>
import Vehicle from '@/components/inventory/Vehicle';

export default {
    components: {
        Vehicle
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        showDescription: {
            type: Boolean,
            default: false
        },
        vehicleId: {
            type: [String, Number],
            requred: true
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        openVehicleGallery() {
            this.$refs.vehicle.openVehicleGallery();
        }
    }
};
</script>