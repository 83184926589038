<template>
    <div>
        <vehicle-images
            v-if="currentVehicle && currentDealer"
            :id="currentVehicle.vehicle_id"
            v-model="showPanelVehicleImage"
            :dealer_id="currentDealer.id" />
        <div class="layout">
            <div class="flex xs12 mb-3">
                <v-toolbar class="title-toolbar mt-4 pl-3">
                    <h2>Vehicle</h2>
                </v-toolbar>
                <!-- Leads section -->

                <div class="gray-icons pl-4 pr-4 pb-4">
                    <loader
                        v-if="loading || dealerLoading"
                        class="pa-5" />

                    <template v-if="currentVehicle">
                        <!--Title block-->
                        <div class="layout">
                            <v-toolbar class="title-toolbar mb-2">
                                <v-toolbar-title class="light-blue--text title-block">
                                    <template>
                                        <a
                                            :href="currentVehicle.vdp_url"
                                            target="_blank">
                                            {{ currentVehicle.year }} {{ currentVehicle.make_name }} {{ currentVehicle.model_name }} {{ currentVehicle.trim_name }}
                                        </a>
                                    </template>
                                </v-toolbar-title>
                                <v-spacer />
                                <v-toolbar-items>
                                    <div class="btn-box mb-0 ml-3">
                                        <action-button
                                            class="mx-2"
                                            icon="browser"
                                            @click="onViewVDP">
                                            view vdp
                                        </action-button>
                                        <action-button
                                            v-if="showDetailsLink"
                                            class="mx-2"
                                            icon="details"
                                            @click="onViewDetail">
                                            view details
                                        </action-button>
                                        <action-button
                                            class="mx-2"
                                            icon="camera"
                                            @click="onViewGallery">
                                            gallery
                                        </action-button>
                                    </div>
                                </v-toolbar-items>
                            </v-toolbar>
                        </div>
                        <div class="pb-1 current-status">
                            VIN: {{ currentVehicle.vin }}
                            <action-button
                                v-clipboard:copy="currentVehicle.vin"
                                v-clipboard:success="onCopy"
                                icon="copy" />
                        </div>

                        <!-- vehicle detail section started -->
                        <div class="layout pt-1 pb-4">
                            <div class="flex xs12 pr-5">
                                <!-- vehicle detail section starts-->
                                <div class="layout pt-3">
                                    <v-toolbar-title class=" title-block-small text-uppercase">
                                        vehicle details
                                    </v-toolbar-title>
                                </div>
                                <div class="layout">
                                    <div class="flex xs4 pt-2">
                                        <img
                                            class="vehicle-image"
                                            :src="vehicleImage">
                                    </div>
                                    <div class="flex xs4 pt-5">
                                        <div class="layout">
                                            <div class="flex xs8">
                                                <data-field>
                                                    <template #label>
                                                        Dealer
                                                    </template>
                                                    <router-link :to="{ name: 'dealer', params: { dealer_id: currentDealer.id}}">
                                                        {{ currentDealer.name }}
                                                    </router-link>
                                                </data-field>
                                            </div>
                                            <div class="flex">
                                                <data-field>
                                                    <template #label>
                                                        Dealer ID
                                                    </template>
                                                    <router-link :to="{ name: 'dealer', params: { dealer_id: currentDealer.id}}">
                                                        {{ currentDealer.id }}
                                                    </router-link>
                                                </data-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex offset-xs1 pt-5">
                                        <data-field>
                                            <template #label>
                                                Vin
                                            </template>
                                            <router-link :to="{ name: 'inventory-id', params: { dealer_id: currentDealer.id, id: currentVehicle.vehicle_id }}">
                                                {{ currentVehicle.vin }}
                                            </router-link>
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Year
                                            </template>
                                            {{ currentVehicle.year }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Price
                                            </template>
                                            {{ currentVehicle.price | numFormat('$0,0') }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Interior Color
                                            </template>
                                            {{ currentVehicle.interior_color }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Trim
                                            </template>
                                            {{ currentVehicle.trim_name }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Stock
                                            </template>
                                            {{ currentVehicle.stock_number }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Mileage
                                            </template>
                                            {{ currentVehicle.mileage | numFormat('0,0') }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Exterior Color
                                            </template>
                                            {{ currentVehicle.exterior_color }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Make
                                            </template>
                                            {{ currentVehicle.make_name }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Model
                                            </template>
                                            {{ currentVehicle.model_name }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                MSRP
                                            </template>
                                            {{ currentVehicle.msrp | numFormat('$0,0') }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Body
                                            </template>
                                            {{ currentVehicle.body_name }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Condition
                                            </template>
                                            {{ vehicleCondition }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout pt-4 pb-4">
                                    <v-divider />
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Fuel Type
                                            </template>
                                            {{ currentVehicle.fuel_type_name ?? '--' }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Drivetrain
                                            </template>
                                            {{ currentVehicle.drivetrain }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                MPG (mpg_city)
                                            </template>
                                            {{ currentVehicle.mpg_city }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                MPG (Highway)
                                            </template>
                                            {{ currentVehicle.mpg_highway }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Date on lot
                                            </template>
                                            {{ vehicleDateOnLot }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Date Added
                                            </template>
                                            {{ vehicleDateAdded }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Vehicle Type
                                            </template>
                                            {{ currentVehicle.vehicle_type_name }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Transmission
                                            </template>
                                            {{ currentVehicle.transmission }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Custom 0
                                            </template>
                                            {{ currentVehicle.custom_label_0 ?? '--' }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding">
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Custom 1
                                            </template>
                                            {{ currentVehicle.custom_label_1 ?? '--' }}
                                        </data-field>
                                    </div>
                                    <div class="flex xs4">
                                        <data-field>
                                            <template #label>
                                                Custom 2
                                            </template>
                                            {{ currentVehicle.custom_label_2 ?? '--' }}
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding mt-4">
                                    <div class="flex">
                                        <data-field>
                                            <template #label>
                                                Description
                                            </template>
                                            <truncate-text
                                                :value="currentVehicle.description"
                                                :characters="260" />
                                        </data-field>
                                    </div>
                                </div>
                                <div class="layout left-padding mt-4">
                                    <div class="flex">
                                        <data-field>
                                            <template #label>
                                                Features
                                            </template>
                                            <truncate-text
                                                :value="currentVehicle.features ?? ''"
                                                :characters="260" />
                                        </data-field>
                                    </div>
                                </div>
                                <!-- vehicle detail section ends -->

                                <!-- vehicle location section starts -->
                                <template v-if="currentVehicle.location">
                                    <div class="layout pt-3">
                                        <v-toolbar-title class=" title-block-small text-uppercase">
                                            vehicle location
                                        </v-toolbar-title>
                                    </div>
                                    <div class="layout wrap">
                                        <div class="md6 xs12 flex">
                                            <div class="layout pt-3 px-2 wrap">
                                                <div
                                                    v-if="vehicleLocation.address.trim()"
                                                    class="md6 xs6 mb-3 flex">
                                                    <data-field>
                                                        <template #label>
                                                            Address
                                                        </template>
                                                        {{ vehicleLocation.address }}
                                                    </data-field>
                                                </div>
                                                <div
                                                    v-if="vehicleLocation.city"
                                                    class="md6 xs6 mb-3 flex">
                                                    <data-field>
                                                        <template #label>
                                                            City
                                                        </template>
                                                        {{ vehicleLocation.city }}
                                                    </data-field>
                                                </div>
                                                <div
                                                    v-if="vehicleLocation.state"
                                                    class="md6 xs6 mb-3 flex">
                                                    <data-field>
                                                        <template #label>
                                                            State
                                                        </template>
                                                        {{ vehicleLocation.state }}
                                                    </data-field>
                                                </div>
                                                <div
                                                    v-if="vehicleLocation.zip"
                                                    class="md6 xs6 mb-3 flex">
                                                    <data-field>
                                                        <template #label>
                                                            Zip Code
                                                        </template>
                                                        {{ vehicleLocation.zip }}
                                                    </data-field>
                                                </div>
                                                <div
                                                    v-if="vehicleLocation.country_code"
                                                    class="md6 xs6 mb-3 flex">
                                                    <data-field>
                                                        <template #label>
                                                            Country
                                                        </template>
                                                        {{ vehicleLocation.country_code }}
                                                    </data-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="currentVehicle.location"
                                            class="md6 xs12 pt-3 px-2 flex">
                                            <gmap-map
                                                :center="coordinates"
                                                :zoom="13"
                                                :options="{
                                                    zoomControl: true,
                                                    mapTypeControl: false,
                                                    scaleControl: false,
                                                    streetViewControl: false,
                                                    rotateControl: false,
                                                    fullscreenControl: false,
                                                    disableDefaultUi: true
                                                }"
                                                style="width: 100%; height: 100%; min-height: 300px;">
                                                <gmap-marker :position="coordinates" />
                                            </gmap-map>
                                        </div>
                                    </div>
                                    <div class="layout pt-4 pb-4">
                                        <v-divider />
                                    </div>
                                </template>
                                <!-- vehicle location section ends -->

                                <!-- status section starts -->
                                <div
                                    v-if="currentVehicle.status"
                                    class="layout pt-1">
                                    <v-toolbar-title class=" title-block-small text-uppercase">
                                        catalog status
                                    </v-toolbar-title>
                                </div>
                                <div
                                    v-if="currentVehicle.status"
                                    class="layout wrap left-padding">
                                    <div
                                        v-for="(channel, channelIndex) in currentVehicle.status.channels"
                                        :key="channelIndex"
                                        class="flex xs4 catalog-status-item">
                                        <data-field>
                                            <template #label>
                                                {{ allChannels[channel] }}
                                            </template>
                                            <img src="/img/dot-green.png">
                                            <span>Eligible</span>
                                        </data-field>
                                    </div>
                                    <div
                                        v-for="(values, key) in currentVehicle.status.reasons"
                                        :key="key"
                                        class="flex xs4 catalog-status-item">
                                        <data-field>
                                            <template #label>
                                                {{ key }}
                                            </template>
                                            <div
                                                v-for="v in values"
                                                :key="v"
                                                class="layout">
                                                <img
                                                    src="/img/dot-red.png"
                                                    class="mt-1">
                                                <span>{{ v }}</span>
                                            </div>
                                        </data-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataField from '../globals/DataField';
import GmapMap from 'vue2-google-maps/src/components/map';
import GmapMarker from 'vue2-google-maps/src/components/marker';
import Loader from '../globals/Loader';
import ActionButton from '@/components/globals/ActionButton';
import VehicleImages from './VehicleImages';
import { mapState } from 'vuex';
import TruncateText from '@/components/globals/TruncateText';
import { format } from 'date-fns';

export default {
    name: 'VehicleDetail',
    components: {
        DataField,
        GmapMarker,
        GmapMap,
        Loader,
        ActionButton,
        VehicleImages,
        TruncateText
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: [Number, String],
        archivedOptions: {
            type: Array,
            default() {
                return ['Active', 'Inactive'];
            }
        },
        showDetailsLink: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            raw: [],
            search: '',
            currentVehicle: null,
            loading: false,
            filteredReasons: [],
            allChannels: null,
            panelVehicleID: null,
            showPanelVehicleImage: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
            dealerLoading: (state) => state.dealer.dealerLoading
        }),
        coordinates() {
            if (!this.vehicleLocation.latitude || !this.vehicleLocation.longitude) {
                return null;
            }
            return {
                lat: parseFloat(this.vehicleLocation.latitude),
                lng: parseFloat(this.vehicleLocation.longitude)
            };
        },
        vehicleImage() {
            return this.currentVehicle.images[0] ?? '';
        },
        vehicleDateOnLot() {
            if (this.currentVehicle.date_first_on_lot) {
                return format(this.currentVehicle.date_first_on_lot, 'MMM D, YYYY');
            }
            return '--';
        },
        vehicleDateAdded() {
            if (this.currentVehicle.created_at.date) {
                return format(this.currentVehicle.created_at.date, 'MMM D, YYYY');
            }
            return '--';
        },
        vehicleLocation() {
            return this.currentVehicle.location || {};
        },
        vehicleCondition() {
            switch (true) {
                case this.currentVehicle.certified && this.currentVehicle.used:
                    return 'Certified Pre-Owned';
                case this.currentVehicle.used:
                    return 'Used';
                default:
                    return 'New';
            }
        },
    },
    watch: {
        value(n) {
            if (n == true) {
                this.getVehicle();
            }
        }
    },
    created() {
        this.getVehicle();
    },
    methods: {
        async getVehicle() {
            if(this.id === null) {
                return;
            }
            this.loading = true;
            try {
                const vehicleData = {
                    q: '',
                    filters: {
                        dealer_id: this.currentDealerId,
                        vehicle_id: this.id
                    },
                    aggregations: [],
                    page: 1,
                    page_size: 1
                };
                const response = await this.$apiRepository.getInventoryVehicles(vehicleData);
                this.currentVehicle = response.data.data[0];

                // This dummy data
                this.allChannels = {
                    'TikTok': 'TikTok Ad Catalog',
                    'FacebookMarket': 'Facebook Marketplace',
                    'Pinterest': 'Pinterest Ad Catalog',
                    'Snapchat': 'Snapchat Ad Catalog',
                    'Microsoft': 'Microsoft/MSN Marketplace',
                    'FacebookAd': 'Facebook Ad Catalog'
                };
                // this.currentVehicle.status = {
                //     channels: [
                //         'FacebookMarket',
                //         'Microsoft',
                //         'TikTok',
                //         'Snapchat',
                //     ],
                //     reasons: {
                //         'Pinterest': [
                //             'Requires more than 2 images',
                //             'Requires mileage',
                //         ],
                //         'FacebookAd': [
                //             'Requires more than 2 images',
                //         ]
                //     }
                // };
                // This dummy data
            } catch(e) {
                console.log(e);
                this.error = true;
            } finally {
                this.loading = false;
                await this.$nextTick();
                this.$emit('loaded');
            }
        },
        onCopy(e) {
            this.$flash('\'' + e.text + '\' copied to clipboard!', 'green');
        },
        onViewVDP() {
            window.open(this.currentVehicle.vdp_url, '_blank');
        },
        onViewGallery() {
            this.showPanelVehicleImage = true;
        },
        onViewDetail() {
            const routeData = this.$router.resolve({
                name: 'inventory-id',
                params: {
                    dealer_id: this.currentDealer.id,
                    id: this.currentVehicle.vehicle_id
                }
            });
            window.open(routeData.href, '_blank');
        },
        handleSlideoutToggle($event) {
            this.$emit('input', $event);
            this.currentVehicle = null;
        },
    },
};
</script>
<style lang="scss" scoped>
.left-padding {
    padding: 10px 0px 0px 20px;

    img {
        width: 10px;
        height:10px;
        margin-right:10px;
    }
}
</style>
<style>
.flex.xs4.text-center {
    margin: auto;
}

.catalog-status-item {
    margin-top:10px;
}
.vehicle-image {
    width:90%;
}
</style>
<style>
.gray-icons .vehicle-carousel .theme--light.v-icon {
    color: white;
}
.image-slider {
    width: 300px!important;
    height:220px!important;
}
</style>


