<template>
    <div>
        <styled-slideout
            :value="value"
            :width="800"
            :go-to-bottom="goToBottom"
            @input="$emit('input', $event)">
            <vehicle-detail
                :id="id"
                ref="vehicleDetail"
                :show-details-link="true"
                :value="value"
                @loaded="vehicleLoaded" />
        </styled-slideout>
    </div>
</template>
<script>
import VehicleDetail from './VehicleDetail';
import StyledSlideout from '@/components/globals/StyledSlideout';


export default {
    name: 'Vehicle',
    components: {
        StyledSlideout,
        VehicleDetail,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        showDescription: {
            type: Boolean,
            default: false
        },
        id: [Number, String],
    },
    data() {
        return {
            goToBottom: false,
        };
    },
    methods: {
        openVehicleGallery() {
            this.$nextTick(() => {
                this.$refs.vehicleDetail.onViewGallery();
            });
        },
        async vehicleLoaded() {
            if (this.showDescription) {
                this.goToBottom = true;
            }
            await this.$nextTick();
            this.goToBottom = false;
        }
    }
};
</script>


